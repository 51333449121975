import { onAuthStateChanged } from "firebase/auth";
import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { auth } from "../../firebase";
import Login from "../../pages/Login";
import PrivateChat from "../../pages/Private-Chat";
import { useLottie } from "lottie-react";
import animationData from "../../assets/images/loding.json";
import UserProvider from "../../context/UserProvider";
import Feedbacks from "../../pages/Feedbacks";
import PromptModalProvider from "../../context/PromptModalProvider";
import FeedbackProvider from "../../context/FeedBackProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Navigation() {
  const [loggedIn, setloggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 1500);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setloggedIn(true);
      } else {
        setloggedIn(false);
      }
    });
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(lottieOptions);

  if (loading) {
    return <div className="lodingmain">{View}</div>;
  }
  if (!loggedIn) {
    return (
      <PromptModalProvider>
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<Login />}></Route>
        </Routes>
      </PromptModalProvider>
    );
  }
  return (
    <PromptModalProvider>
      <ToastContainer />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <UserProvider>
              <PrivateChat />
            </UserProvider>
          }
        ></Route>
        <Route
          exact
          path="/feedbacks"
          element={
            <FeedbackProvider>
              <Feedbacks />
            </FeedbackProvider>
          }
        ></Route>
      </Routes>
    </PromptModalProvider>
  );
}
