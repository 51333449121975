import Navigation from "./component/Navigation";
import { BrowserRouter } from "react-router-dom";
import "../src/assets/css/style.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
