import {
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { useContext } from "react";
import { auth } from ".";
import { PromptModalContext } from "../context/PromptModalProvider";

export const useFirebaseAuth = () => {
  const {showToast} = useContext(PromptModalContext)

  const firebasePhoneAuth = async (phoneNumber) => {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      window.confirmationResult = confirmationResult;
    
  };
  
  const verifyOTP = async (code) => {
    const confirmationResult = window.confirmationResult
    await confirmationResult.confirm(code)
  };
  
  const logout = async () => {
    try {
      await signOut(auth)
    } catch (error) {
      showToast("Error while signing out!", "error")
    }
  };
  
  return { firebasePhoneAuth, verifyOTP, logout };
};

