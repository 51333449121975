export const dateConversation = (createdAt) => {
  const timestamp = new Date(
    createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
  );

  // Specify options to exclude seconds
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return timestamp.toLocaleString(undefined, options);
};

export const realTimeDatabaseDateConversion = (createdAt) => {
  const date = new Date(createdAt);
  
  const year = date.getFullYear().toString().slice(-2);
  const month = date.getMonth() + 1; // Month is zero-based, so we add 1
  const day = date.getDate();
  
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12;

  const formattedDate = `${year}/${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}`;
  const formattedTime = `${displayHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
};


