import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Close from "../icons/close";
import { PromptModalContext } from "../../context/PromptModalProvider";

export default function PromptModal({ closeModal, IsOpen }) {
  const [isEdit, setIsEdit] = useState(false);
  const { addPromptMessage, promptMessages,showToast } = useContext(PromptModalContext);
  const [promptMessage, setPromptMessage] = useState(
    promptMessages !== "" ? promptMessages : ""
  );

  const enableEdit = (e) => {
    e.preventDefault();
    setIsEdit(true);
  };
  const saveclick = async (e) => {
    if (promptMessage !== promptMessages) {
      e.preventDefault();
      // create new collection name as prompt message in firebase firestore using uid as name and store text in it
      await addPromptMessage(promptMessage);
        setIsEdit(false)
        closeModal();
    } else {
      //error handling message or error message
      showToast("Nothing to update!","info")
    }
  };

  useEffect(()=>{
    setPromptMessage(promptMessages)
  },[promptMessages])

  return (
    <div className="prompt-Modalmain">
      <Modal
        isOpen={IsOpen}
        onRequestClose={closeModal}
        className="Modalmain-main"
      >
        <div className="chaatnow">
          <h4>Prompt</h4>
          <a onClick={closeModal}>
            <Close />
          </a>
        </div>
        <form className="forme-main">
          <textarea
            name="message"
            value={promptMessage}
            onChange={(e) => setPromptMessage(e.target.value)}
            disabled={isEdit ? false : true}
          >
            {promptMessage}
          </textarea>
          <div className="dataedit">
            <button className="btn editbtn" onClick={enableEdit}>
              Edit
            </button>
            <button
              disabled={promptMessage === promptMessages ? true : false}
              className="btn"
              onClick={saveclick}
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
