import React, { useState } from "react";
import Header from "../../component/header";
import UserChatModal from "../../component/UserChatModal";
import Chat from "./chat";

export default function PrivateChat() {
  const [userChat, setUserChat] = useState(false);
  const handaleUserChat = () => {
    setUserChat(window.innerWidth <= 992 ? true : false);
  };
  const handaleUserChatClose = () => {
    setUserChat(false);
  };

  return (
    <div className="container-fluid">
      <div className="privateChat">
        <Header handaleUserChat={handaleUserChat} />
        <UserChatModal
          handaleUserChatClose={handaleUserChatClose}
          isOpen={userChat}
        />
        <Chat />
      </div>
    </div>
  );
}
