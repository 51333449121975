import React, { createContext, useEffect, useState } from "react";

import { onValue, ref, serverTimestamp, set, update } from "firebase/database";
import { db, realTimeDatabase } from "../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";

// Create the context
export const PromptModalContext = createContext();

// Create the provider component
const PromptModalProvider = ({ children }) => {
  const [promptMessages, setPromptMessages] = useState("");
  const [loader, setLoader] = useState(false);

  const showToast = (message, type) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 1500, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const addPromptMessage = async (promptMessages) => {
    setLoader(true);
    const docId = "prompt";
    const docRef = ref(realTimeDatabase, `/${docId}`);
    const promptFirestoreRef = collection(db, docId);

    try {
      // Add data to Realtime Database
      await set(docRef, {
        message: promptMessages,
        createdAt: serverTimestamp(),
      });

      // Add data to Firestore
      const firestoreResult = await addDoc(promptFirestoreRef, {
        message: promptMessages,
        // i want add createdAt to firestore
        createdAt: new Date(),
      });

      // Update Realtime Database with Firestore document ID
      await update(docRef, {
        promptId: firestoreResult.id,
      });

      // Update Firestore with Firestore document ID
      const promptFirestoreDocRef = doc(promptFirestoreRef, firestoreResult.id);
      await updateDoc(promptFirestoreDocRef, {
        promptId: firestoreResult.id,
      });

      // Replace getPromptMessages with your actual function to retrieve data
      const updatedData = await getPromptMessages();
      showToast("Prompt Messages Updated Successfully", "success");
      return updatedData; // Return the key and value pairs
    } catch (error) {
      showToast("Prompt message update failed!", "error");
      // Handle the error
    } finally {
      setLoader(false);
    }
  };

  const getPromptMessages = async () => {
    const docId = "prompt";
    const docRef = ref(realTimeDatabase, `/${docId}`);
    onValue(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setPromptMessages(data?.message);
        } else {
          setPromptMessages("");
        }
      },
      (error) => {
        showToast("Failed while fetching prompt!", "error");
      }
    );
  };

  useEffect(() => {
    getPromptMessages();
  }, []);
  // Return the context provider with the state and functions
  return (
    <PromptModalContext.Provider
      value={{ addPromptMessage, promptMessages, showToast, loader }}
    >
      {children}
    </PromptModalContext.Provider>
  );
};

export default PromptModalProvider;
