import React from "react";

export default function Send() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4176 8.80125L2.29399 0.936052C2.15533 0.86841 2.00306 0.833252 1.84877 0.833252C1.28792 0.833252 0.833252 1.28792 0.833252 1.84877V1.87808C0.833252 2.01434 0.84996 2.1501 0.88301 2.2823L2.42965 8.46884C2.47189 8.63784 2.61477 8.76267 2.78787 8.78192L9.58584 9.53725C9.82159 9.56342 9.99992 9.76267 9.99992 9.99992C9.99992 10.2372 9.82159 10.4364 9.58584 10.4626L2.78787 11.2179C2.61477 11.2372 2.47189 11.362 2.42965 11.531L0.88301 17.7175C0.84996 17.8498 0.833252 17.9855 0.833252 18.1218V18.1511C0.833252 18.7119 1.28792 19.1666 1.84877 19.1666C2.00306 19.1666 2.15533 19.1314 2.29399 19.0638L18.4176 11.1986C18.8758 10.9751 19.1666 10.5098 19.1666 9.99992C19.1666 9.49 18.8758 9.02475 18.4176 8.80125Z"
        fill="white"
      />
    </svg>
  );
}
