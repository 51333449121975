import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { UserContext } from "../../context/UserProvider";
import Close from "../icons/close";

export default function UserChatModal({ handaleUserChatClose, isOpen }) {
  const { usersDetails, getUserMessages, selectedUserId } =
    useContext(UserContext);
  const [activeIndex, setActiveIndex] = useState("");

  const handleUserClick = (uid) => {
    getUserMessages(uid);
    setActiveIndex(uid);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("uid", uid);
    // Replace the current state with the new URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({ uid }, "", newUrl);
    handaleUserChatClose()
  };

  useEffect(() => {
    if (!selectedUserId && usersDetails.length > 0) {
      handleUserClick(usersDetails[0]?.uid);
    }
  }, [usersDetails.length]);

  return (
    <div className="modalindesktop">
      <div className="users-name desktop">
        <ul>
          {usersDetails?.map((user) => (
            <li
              key={user?.id}
              className={user.id === selectedUserId ? "active" : ""}
              onClick={() => handleUserClick(user?.uid)}
            >
              <span>{user?.displayName?.[0]}</span>
              <label>{user?.displayName}</label>
            </li>
          ))}
        </ul>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={handaleUserChatClose}
        className="userChat"
      >
        <div className="chaatnow">
          <h2>Chats</h2>
          <a href="#" onClick={handaleUserChatClose}>
            <Close />
          </a>
        </div>
        <div className="users-name phonemodal">
          <ul>
            {usersDetails?.map((user) => (
              <li 
              className={user.id === selectedUserId ? "active" : ""}
              onClick={() => handleUserClick(user?.uid)}
              key={user?.id}>
                <span>{user?.displayName?.[0]}</span>
                <label>
                  {user?.displayName} <small></small>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
}
