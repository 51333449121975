import { RecaptchaVerifier } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { auth } from "../../firebase";
import { useFirebaseAuth } from "../../firebase/Authentication";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PromptModalContext } from "../../context/PromptModalProvider";
import Loader from "../../component/icons/Loader";

export default function Login() {
  const [step, setStep] = useState("phone");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("+17322669350");
  const { firebasePhoneAuth, verifyOTP } = useFirebaseAuth();
  const { showToast } = useContext(PromptModalContext);
  const [loader, setLoader] = useState(false);

  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  const handlePhoneInputChange = (value) => {
    const phonevalue = `+${value}`;
    setPhone(phonevalue);
  };

  useEffect(() => {
    auth.useDeviceLanguage();
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "phone-sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // response after captcha verify
        },
      }
    );
  }, []);
  const onSubmitPhone = async (e) => {
    e.preventDefault();
    setLoader(true);
    firebasePhoneAuth(phone)
      .then(() => {
        handleStepChange("otp");
        showToast("Sent verification code!", "success");
      })
      .catch(() => {
        showToast("Invalid Phone Number!", "error");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    setLoader(true);
    verifyOTP(otp)
      .then(() => {
        showToast("OTP verified successfully", "success");
      })
      .catch(() => {
        showToast("Invalid/Expired OTP", "error");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <div className="login-main">
      <div className="modal-main">
        {step == "phone" && (
          <div className="phonenumber">
            <h1>Enter your mobile number to receive One Time Password (OTP)</h1>
            <form className="otpnumber">
              <PhoneInput
                disabled={true}
                onChange={(value) => handlePhoneInputChange(value)}
                value={phone}
                country={"us"}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
              />
              <button disabled={loader} className="btn" onClick={onSubmitPhone}>
                {loader ? <Loader /> : "Send OTP"}
              </button>
            </form>
          </div>
        )}
        {step === "otp" && (
          <div className="otp-number">
            <h1>
              Enter the One Time Password (OTP) received on your mobile number
            </h1>
            <form>
              <div className="otpnumber">
                <OtpInput
                  value={otp}
                  inputType="tel"
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} placeholder="-" />}
                />
              </div>
              <div className="submitnow">
                <button disabled={loader} onClick={onSubmitOTP} className="btn">
                  {loader ? <Loader /> : "Verify"}
                </button>
              </div>
            </form>
          </div>
        )}
        <div id="phone-sign-in-button"></div>
      </div>
    </div>
  );
}
