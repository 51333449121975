// create feed back provider

import { createContext, useEffect, useState,useContext } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { PromptModalContext } from "./PromptModalProvider";

export const FeedbackContext = createContext();
const FeedbackProvider = ({ children }) => {
  const{showToast} =  useContext(PromptModalContext)
  const [feedBackData, setFeedBackData] = useState([]);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const getFeedBackData = () => {
    setLoadingFeedback(true)
    const dataRef = collection(db, "FeedBack");
    getDocs(dataRef).then((data) => {
      const dataArray = [];
      data.forEach((dataDocument) => {
        dataArray.push({
          ...dataDocument?.data(),
          id: dataDocument?.id,
        });
      });
      setFeedBackData(dataArray);

    }).catch(()=>{
      showToast("Error while getting feedback data!","error")
    }).finally(()=>{
      setLoadingFeedback(false)
    })
  };

  useEffect(() => {
    getFeedBackData();
  }, []);
  // Your code here
  return (
    <FeedbackContext.Provider value={{ feedBackData, loadingFeedback }}>
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
