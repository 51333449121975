import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFirebaseAuth } from "../../firebase/Authentication";
import CloseMenu from "../icons/CloseMenu";
import Logout from "../icons/logout";
import MenuIcon from "../icons/menuicon";
import PromptModal from "../PromptModal";

export default function Header({ handaleUserChat }) {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { logout } = useFirebaseAuth();

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.matchMedia("(max-width:768px)").matches;
      setMenuOpen(!isSmallScreen);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handalePromptModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const onClickChat = () => {
    setMenuOpen(false);
    if (handaleUserChat) {
      handaleUserChat();
    }
  };

  return (
    <Fragment>
      <header className={`${isMenuOpen ? "blur" : ""}`}>
        <div className="logo">
          <h1>
            <Link to="/">Shivam.Chat</Link>
          </h1>
        </div>
        <div className="navbar">
          <span onClick={toggleMenu}>
            {isMenuOpen ? <CloseMenu /> : <MenuIcon />}
          </span>
          {isMenuOpen && (
            <ul>
              <li>
                <a onClick={logout}>
                  <Logout /> <span>Logout</span>
                </a>
              </li>
              <li>
                <Link to="/feedbacks">Feedbacks</Link>
              </li>
              <li>
                <Link to="/" onClick={onClickChat} className="chatmenu">
                  <span>Chats</span>
                </Link>
              </li>
            </ul>
          )}
          <button className="btn" onClick={handalePromptModal}>
            Prompt
          </button>
          <Link to="/feedbacks" className="firstmenu">
            Feedbacks
          </Link>
        </div>
      </header>
      <PromptModal closeModal={closeModal} IsOpen={modalOpen} />
    </Fragment>
  );
}
