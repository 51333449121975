// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAgdsl-H8_8eAkKUG7eFikLV6OnVEqZCss",
  authDomain: "shivam-ai.firebaseapp.com",
  projectId: "shivam-ai",
  storageBucket: "shivam-ai.appspot.com",
  messagingSenderId: "69384233188",
  appId: "1:69384233188:web:476e23619a806d38174cf1",
  measurementId: "G-95LT1LQGHC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/**
 * We will use for analytics
 * We will keep it as of now
 */
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const realTimeDatabase = getDatabase(app);
export const auth = getAuth(app);
