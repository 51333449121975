import React, { useContext, useEffect, useRef, useState } from "react";
import Send from "../../component/icons/send";
import Star from "../../component/icons/star";
import { UserContext } from "../../context/UserProvider";
import { auth, realTimeDatabase } from "../../firebase";
import { push, ref, serverTimestamp, set } from "firebase/database";
import { PromptModalContext } from "../../context/PromptModalProvider";
import { realTimeDatabaseDateConversion } from "../../utils/dateConversation";

const Chat = () => {
  const uid = auth?.currentUser?.uid;
  const { showMessages, selectedUserId } = useContext(UserContext);
  const { showToast } = useContext(PromptModalContext);
  const [message, setMessage] = useState("");
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      return;
    }
    if (message.trim() === "") {
      showToast("invalid message", "error");
      return;
    }
    //   const uid = auth.currentUser.uid;
    const userMessagesRef = ref(
      realTimeDatabase,
      `Chats/${selectedUserId}/messages`
    );
    const newMessageRef = push(userMessagesRef);
    await set(newMessageRef, {
      text: message,
      createdAt: serverTimestamp(),
      uid: "ADMIN-CHATGPT",
    });

    setMessage("");
  };

  const chatScrollRef = useRef(null);
  useEffect(() => {
    chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
  }, [showMessages]);

  return (
    <div className="chatspaceing">
      <div className="chatscrolling" ref={chatScrollRef}>
        {showMessages?.map((item, index) => (
          <div key={index} className="fixed-width">
            <div
              className={
                uid === item?.uid || item?.uid == "ADMIN-CHATGPT"
                  ? "chatshow"
                  : "userside"
              }
            >
              <div
                className={
                  uid === item?.uid || item?.uid == "ADMIN-CHATGPT"
                    ? "claintchat"
                    : "mychat"
                }
              >
                {item?.text}
              </div>
              <small>{realTimeDatabaseDateConversion(item.createdAt)}</small>
              {uid === item?.uid || item?.uid == "ADMIN-CHATGPT" ? (
                <Star />
              ) : (
                <span>{"U"}</span>
              )}
            </div>
          </div>
        ))}
      </div>

      <form className="search-massage">
        <input
          className="inputfield"
          name="name"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          type="text"
          placeholder="Send a message"
        />
        <button onClick={sendMessage} className="btn">
          <Send />
        </button>
      </form>
    </div>
  );
};

export default Chat;
