import { onValue, ref } from "firebase/database";
import { collection, getDocs } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db, realTimeDatabase } from "../firebase";

import { PromptModalContext } from "./PromptModalProvider";
export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [usersDetails, setUsersDetails] = useState([]);
  const [showMessages, setShowMessages] = useState([]);
  const {showToast} = useContext(PromptModalContext);
  const queryParams = new URLSearchParams(window.location.search);
  const selectedUserId = queryParams.get("uid");

  // fetch user Data
  const getUserData = () => {
    const userDataRef = collection(db, "Users");
    getDocs(userDataRef).then((data) => {
      const dataArray = [];
      data.forEach((userDocument) => {
        dataArray.push({
          ...userDocument?.data(),
          id: userDocument?.id,
        });
      });
      setUsersDetails(dataArray);
    }).catch((error)=>{
      showToast("Something went wrong! - Users","error")
    })
  };

  // get message data from realtime database collection
  const getUserMessages = (uid) => {
    const userMessagesRef = ref(realTimeDatabase, `Chats/${uid}/messages`);

    onValue(userMessagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const messagesData = snapshot.val();
        const messagesArray = Object.values(messagesData);
        setShowMessages(messagesArray);
      } else {
        setShowMessages([]);
      }
    });
  };

  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    if (selectedUserId) {
      getUserMessages(selectedUserId);
    }
  }, [selectedUserId]);

  return (
    <UserContext.Provider
      value={{
        usersDetails,
        getUserMessages,
        showMessages,
        selectedUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
