import React from "react";

export default function Logout() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4175 13.1249C15.5625 13.1249 15.705 13.0749 15.825 12.9749L18.7325 10.4824C18.7475 10.4699 18.7625 10.4549 18.7775 10.4424C18.8275 10.3924 18.8675 10.3349 18.8975 10.2749C18.91 10.2499 18.92 10.2224 18.93 10.1949C18.93 10.1949 18.93 10.1949 18.93 10.1924C18.9375 10.1649 18.945 10.1374 18.95 10.1099C18.955 10.0749 18.9575 10.0424 18.9575 10.0074C18.9575 9.97241 18.955 9.93991 18.95 9.90491C18.945 9.87741 18.9375 9.84991 18.93 9.82241C18.93 9.82241 18.93 9.82241 18.93 9.81991C18.92 9.79241 18.91 9.76491 18.8975 9.73991C18.8675 9.67991 18.8275 9.62241 18.7775 9.57241C18.7625 9.55741 18.7475 9.54491 18.7325 9.53241L15.8225 7.02491C15.56 6.79991 15.165 6.82991 14.94 7.09241C14.715 7.35491 14.745 7.74991 15.0075 7.97491L16.6425 9.37491H7.50002C7.15502 9.37491 6.87502 9.65491 6.87502 9.99991C6.87502 10.3449 7.15502 10.6249 7.50002 10.6249H16.645L15.01 12.0249C14.7475 12.2499 14.7175 12.6449 14.9425 12.9074C15.065 13.0499 15.24 13.1249 15.4175 13.1249Z"
        fill="#576579"
      />
      <path
        d="M5.83242 18.9574C6.17492 18.9574 6.45492 18.6799 6.45742 18.3349C6.45992 17.9899 6.17992 17.7074 5.83493 17.7074C4.09993 17.6974 3.29992 17.6199 2.83992 17.1599C2.28992 16.6099 2.28992 15.5149 2.28992 13.3349V6.66742C2.28992 4.48492 2.28992 3.38992 2.83992 2.84242C3.38992 2.29242 4.48492 2.29242 6.66492 2.29242H7.49992C9.68242 2.29242 10.7774 2.29242 11.3249 2.84242C11.7849 3.30242 11.8624 4.10242 11.8724 5.83742C11.8749 6.18242 12.1549 6.45992 12.4974 6.45992H12.4999C12.8449 6.45742 13.1224 6.17742 13.1224 5.83242C13.1124 3.97992 13.0449 2.79742 12.2074 1.95992C11.7549 1.50742 11.1749 1.25492 10.3249 1.14242C9.60242 1.04492 8.71992 1.04492 7.49742 1.04492H6.66492C5.44242 1.04492 4.55992 1.04492 3.83742 1.14242C2.98742 1.25742 2.40492 1.50992 1.95492 1.95992C1.50242 2.41242 1.24992 2.99242 1.13742 3.84242C1.03992 4.56492 1.03992 5.44742 1.03992 6.66992V13.3374C1.03992 14.5599 1.03992 15.4424 1.13742 16.1649C1.25242 17.0149 1.50492 17.5974 1.95492 18.0474C2.79242 18.8849 3.97742 18.9499 5.82742 18.9624C5.82992 18.9574 5.83242 18.9574 5.83242 18.9574ZM9.16742 18.9449C9.17492 18.9449 9.17992 18.9449 9.18742 18.9449C10.5749 18.9024 11.5124 18.7424 12.2124 18.0449C13.0499 17.2074 13.1149 16.0224 13.1274 14.1724C13.1299 13.8274 12.8499 13.5449 12.5049 13.5449H12.5024C12.1599 13.5449 11.8799 13.8224 11.8774 14.1674C11.8674 15.9024 11.7899 16.7024 11.3299 17.1624C10.9724 17.5199 10.4024 17.6599 9.15242 17.6974C8.80742 17.7074 8.53492 17.9949 8.54742 18.3399C8.55242 18.6774 8.82992 18.9449 9.16742 18.9449Z"
        fill="#576579"
      />
    </svg>
  );
}
