import React from "react";

export default function CloseMenu() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="1.41431"
        width="2"
        height="26.2804"
        rx="1"
        transform="rotate(-45 0 1.41431)"
        fill="black"
      />
      <rect
        x="18.5831"
        width="2"
        height="26.2804"
        rx="1"
        transform="rotate(45 18.5831 0)"
        fill="black"
      />
    </svg>
  );
}
