import React, { useContext, useState } from "react";
import Header from "../../component/header";
import Loader from "../../component/icons/Loader";
import { FeedbackContext } from "../../context/FeedBackProvider";
import { dateConversation } from "../../utils/dateConversation";
export default function Feedbacks() {
  const [expandedId, setExpandedId] = useState(null);
  const { feedBackData, loadingFeedback } = useContext(FeedbackContext);

  const handleToggle = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };
  return (
    <div className="container-fluid">
      <div className="privateChat">
        <Header />
      </div>
      <div className="feedbacks-main">
        <h3>Feedbacks</h3>
        <div className="phonescroll">
          <table className="feedbacks-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Description</th>
                <th>Date & Time</th>
              </tr>
              {feedBackData.map((feedback) => (
                <tr key={feedback.id}>
                  <td>{feedback.name}</td>
                  <td>{feedback.emailOrPhone}</td>
                  <td>
                    {expandedId === feedback.id
                      ? feedback.feedBakMessage
                      : feedback.feedBakMessage?.length > 240
                      ? `${feedback.feedBakMessage.substring(0, 240)}... `
                      : feedback.feedBakMessage}
                    {feedback.feedBakMessage?.length > 240 && (
                      <a href="#" onClick={() => handleToggle(feedback.id)}>
                        {expandedId === feedback.id ? "read less" : "read more"}
                      </a>
                    )}
                  </td>
                  <td>{dateConversation(feedback.createdAt)}</td>
                </tr>
              ))}
              {!loadingFeedback && feedBackData.length <= 0 ? (
                <tr>
                  <td align="center" colSpan="4">
                    No Feedback available!
                  </td>
                </tr>
              ) : (
                ""
              )}
              {loadingFeedback && (
                <tr>
                  <td align="center" colSpan="4">
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
